<template>
  <div v-if="ticketData">
    <b-card :title="$i18n.t('Ticket ID: #') + ticketData._id" v-if="ticketData">
      <div>
        <div>{{ $t("Nome completo") }}:</div>
        <h5>{{ ticketData.user.fullname }}</h5>
      </div>
      <div>
        <div>{{ $t("Usuário") }}: </div>
        <h5>{{ ticketData.user.username }}</h5>
      </div>
      <div>
        <div>{{ $t("E-mail") }}: </div>
        <h5>{{ ticketData.user.email }}</h5>
      </div>
      <div>
        <div>{{ $t("Saldo") }}: </div>
        <h5>{{ moneyFormat(ticketData.user.balance) }}</h5>
      </div>
      <div>
        <div>{{ $t("Upline") }}: </div>
        <h5>{{ ticketData.user.upline.fullname }} | {{
          ticketData.user.upline.email }}</h5>
      </div>
      <div>
        <div>{{ $t("Status") }}:</div>
        <h5>{{ $t(ticketData.status) }}</h5>
      </div>
      <div>
        <div>{{ $t("Assunto") }}:</div>
        <h5>{{ ticketData.subject }}</h5>
      </div>
      <div>
        <div>{{ $t("Descrição do problema") }}:</div>
        <h5>{{ ticketData.description }}</h5>
      </div>
      <div>
        <div>{{ $t("Categoria") }}:</div>
        <h5>{{ $t(ticketData.category) }}</h5>
      </div>
      <div v-if="ticketData.attachments.length > 0">
        <div>{{ $t("Imagem anexada") }}:</div>
        <div class="text-center">
          <b-img :src="renderImgAvatar(ticketData.attachments[0])" class="mt-1" />
        </div>
      </div>
    </b-card>
    <b-card :title="$i18n.t('Histórico da conversa')">
      <div v-for="(conversation, index) in ticketData.conversation" :key="index">
        <b-card :bg-variant="conversation.user.attendentId == returnUser._id
            ? 'light-warning'
            : 'light-info'
          ">
          <div class="d-flex flex-grow-1 align-items-center">
            <b-avatar size="32" :src="renderImgAvatar(conversation.user.avatar)"
              :text="avatarText(conversation.user.username)" class="mr-1" />
            <strong class="mr-auto text-white">{{
              conversation.user.username
            }}</strong>
            <small class="text-white">{{
              format(
                new Date(conversation.user.createdAt),
                "dd/MM/yyyy HH:mm:ss"
              )
            }}</small>
          </div>
          <div class="mt-1 mx-1">
            {{ conversation.response }}
          </div>
        </b-card>
      </div>
      <div v-if="ticketData.status == 'Open'">
        <hr />
        <div class="d-flex">
          <div class="d-flex flex-column w-100">
            <b-form-textarea id="problem-description" v-model="response"
              :placeholder="$i18n.t('Por favor, digite a sua resposta')" rows="3"></b-form-textarea>
            <small class="text-warning">É necessário preencher esse campo para responder ou fechar o
              ticket</small>
          </div>
          <!-- <div class="ml-2">
            <h6>
              {{ $t("Gostaria de anexar algum print?") }}
            </h6>
            <avatar-img
              :textNoImg="'I M G'"
              :rounded="'sm'"
              @avatarFile="(val) => (printFile = val)"
            />
          </div> -->
        </div>
        <div class="d-flex justify-content-start mt-1">
          <b-button class="mr-1" variant="outline-success" @click="() => responseTicket('Open')"
            :disabled="response.length == 0">{{ $t("Responder") }}</b-button>
          <b-button variant="outline-info" @click="() => responseTicket('Closed')" :disabled="response.length == 0">{{
            $t("Fechar ticket") }}</b-button>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { avatarText } from "@core/utils/filter";
import { BFormTextarea } from "bootstrap-vue";
import { format } from "date-fns";
import { mapGetters } from "vuex";

export default {
  components: {
    BFormTextarea,
  },
  data() {
    return {
      ticketData: null,
      response: "",
    };
  },
  computed: {
    ...mapGetters(["returnUser"]),
  },
  mounted() {
    this.getTicketData();
  },
  methods: {
    getTicketData() {
      this.$store
        .dispatch("getTicketSupportTeam", this.$route.params.idTicket)
        .then((resp) => {
          this.ticketData = resp;
        })
    },
    renderImgAvatar(img) {
      if (img)
        return img ? process.env.VUE_APP_API_URL + "/avatar/" + img : null;
    },
    responseTicket(status) {
      const data = {
        ticketId: this.ticketData._id,
        response: this.response,
        status: status,
      };
      this.$store.dispatch("responseTicket", data).then((resp) => {
        this.getTicketData();
        this.response = "";
      });
    },
  },
  setup() {
    format;

    return {
      avatarText,
      format,
    };
  },
};
</script>